<template>
  <div class="table-responsive">
    <table class="table table-hover table-striped table-sm">
      <thead>
        <tr>
          <th />
          <th />
          <th />
          <th
            v-for="(th, index) in list.maintenance_list"
            :key="index"
          >
            {{ th }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(row, index) in list.rows"
          :key="index"
        >
          <td>
            {{ row.brand }}
          </td>
          <td>
            {{ row.model }}
          </td>
          <td>
            {{ row.equipment }}
          </td>
          <td
            v-for="(price, i) in row.price"
            :key="i"
          >
            {{ price }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {

    }
  },
}
</script>
