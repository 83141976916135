<template>
  <b-card>
    <div class="mb-2">
      <div class="d-md-flex align-items-start">
        <b-form-group
          class="mr-1 my-1"
        >
          <Input-file-Simple
            v-model="maintenance"
            v-validate="'required'"
            name="maintenance"
          />
          <small
            v-show="errors.has('maintenance')"
            class="text-danger"
          >{{ errors.first('maintenance') }}</small>
        </b-form-group>
        <b-button
          class="mr-1 my-1"
          variant="primary"
          :disabled="maintenance === '' ? true : false"
          @click="preview"
        >
          Предпросмотр
        </b-button>
        <b-button
          class="mr-1 my-1 ml-auto"
          variant="primary"
          :disabled="previewLoaded ? false : true"
          @click="load"
        >
          Сохранить
        </b-button>
        <b-button
          v-if="previewLoaded"
          class="mr-1 my-1"
          variant="light"
          @click="fetchMaintenance"
        >
          Отмена
        </b-button>
      </div>
    </div>
    <MaintenanceList :list="list" />
  </b-card>
</template>

<script>
import { BCard, BFormGroup, BButton } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import InputFileSimple from '@core/components/input/InputFileSimple.vue'
import Maintenance from '@/api/http/models/maintenance/Maintenance'
import MaintenanceList from './MaintenanceList.vue'

export default {
  components: {
    BCard,
    BFormGroup,
    BButton,
    InputFileSimple,
    MaintenanceList,
  },
  data() {
    return {
      previewLoaded: false,
      maintenance: '',
      list: '',
    }
  },
  computed: {
    ...mapGetters({
      currentSite: 'user/currentSite',
    }),
  },
  async created() {
    await this.fetchMaintenance()
  },
  methods: {
    async fetchMaintenance() {
      const response = await Maintenance.getOne(this.currentSite.slug)
      this.list = response.data || ''
      this.maintenance = ''
      this.previewLoaded = false
    },
    async preview() {
      const response = await Maintenance.preview(this.currentSite.slug, {
        file: this.maintenance,
      })
      this.list = response.data || ''
      this.previewLoaded = true
    },
    async load() {
      const response = await Maintenance.load(this.currentSite.slug, {
        file: this.maintenance,
      })
      this.list = response.data || ''
    },
  },
}
</script>
