<template>
  <label
    :for="id"
    class="upload-file"
    :class="`upload-file--${size}`"
  >
    <div class="upload-file__simple">
      <div v-if="file.filename">{{ file.filename }}  <span v-if="file.size_human_readable">{{ file.size_human_readable }}</span></div>
      <div v-else>Выберите файл</div>
    </div>
    <input
      :id="id"
      ref="file"
      type="file"
      :name="id"
      @input="uploadFile"
    >
  </label>
</template>

<script>
/* eslint no-underscore-dangle: 0 */

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import File from '@/api/http/models/file/File'

export default {
  props: {
    value: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: 'md', // 'sm' | 'md' | 'lg'
    },
  },
  $_veeValidate: {
    value() {
      return this.value
    },
  },
  data() {
    return {
      id: null,
      fileKey: this.value,
      file: {},
    }
  },
  mounted() {
    this.id = `file-input-${this._uid}`
  },
  async created() {
    if (this.fileKey) {
      const response = await File.getOne(this.fileKey)

      if (response.data) {
        this.file = response.data
      }
    }
  },
  methods: {
    async uploadFile() {
      const file = this.$refs.file.files[0]
      const sizeInMB = (file.size / (1024 * 1024)).toFixed(2)

      if (sizeInMB < 3) {
        const formData = new FormData()
        formData.append('file', file)
        const response = await File.upload(formData)
        if (response.status === 'success') {
          this.file = response.data
          this.fileKey = this.file.key
          this.$emit('input', this.fileKey)
          this.$emit('uploaded', this.file)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Файл загружен на сервер',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Произошла ошибка при загрузке файла',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Размер файла превышает 3MB',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
    deleteImage() {
      this.file = {}
      this.$emit('input', null)
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/base/bootstrap-extended/variables';

  .upload-file {
    display: flex;
    cursor: pointer;

    &__simple {
      min-width: 20rem;
      padding: 0 1rem;
      line-height: 36px;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #d8d6de;
      border-radius: 0.357rem;

      &:focus {
        box-shadow: 0 3px 10px 0 rgba(34, 41, 47, 0.1);
      }
    }

    &:hover {
      .upload-file__preview {
        background: $primary;
        color: #fff;
      }
    }

    &--sm {
      .upload-file__preview {
        width: 60px;
        height: 60px;
      }
    }

    &--md {
      .upload-file__preview {
        width: 100px;
        height: 100px;
      }
    }

    &--lg {
      .upload-file__preview {
        width: 200px;
        height: 200px;
      }
    }

    input {
      opacity: 0;
      position: absolute;
      z-index: -1;
    }
  }
</style>
